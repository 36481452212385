.sudoSu {
    position: fixed;
    right: 30px;
    bottom: 30px;
    z-index: 99999;

    &__btn {
        font-size: 1.5em;
        background: #333;
        width: 40px;
        height: 40px;
        line-height: 40px;
        color: white;
        text-align: center;
        border-radius: 50%;

        &--hasSudoed {
            background: #00b067;
        }
    }

    &__interface {
        position: absolute;
        right: 60px;
        bottom: 8px;

        &--hasSudoed {
            background: white;
            padding: 20px;
            bottom: 0;
            border: 1px solid #cecece;
            border-radius: 5px;
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
            width: 400px;
        }
    }

    &__infoLine {
        margin-bottom: 5px;

        > span {
            font-weight: bold;
        }
    }

    &__resetBtn {
        -webkit-appearance: none;
        -moz-appearance: none;
        cursor: pointer;
        border: 0;
        margin: 5px 0 15px 0;
        padding: 6px 10px;
        background: #00b067;
        color: white;
        border-radius: 4px;
        border-bottom: 2px solid #088b54;

        &:hover {
            background: #088b54;
        }
    }
}

.hidden {
    display: none;
}